import EvectionSearch from "../component/evectionSearch";
import EvectionList from "../component/evectionList";
import WarningBox from "../component/warningBox";

import evectionList from '@/lib/data-service/haolv-default/consumer_evection_evectionList'
export default {
    data() {
        return {
            formData: {
                currentPage: 1,
                pageSize: 10,
                selectType: 1,//1.我的出差，2.我的预订,
                total: 0,
                evectionNo: '',
                evectionReason: '',
            },
            loading: false,
            params: {
                evectionStatus: '',
                evectionType: ''
            },
            detailList: []
        }
    },
    components: {
        EvectionSearch,
        EvectionList,
        WarningBox
    },
    mounted() {
        this.getList()
    },
    methods: {
        getList() {
            let data = Object.assign(this.formData, this.params)
            this.loading = true
            evectionList(data).then(res => {
                console.log(res)
                this.loading = false
                this.detailList = res.datas.list
                this.formData.total = res.datas.totalCount
            }).catch(() => {
                this.loading = false
            })
        },
        handleCurrentChange(val) {
            this.formData.currentPage = val
            this.getList()
        }
    }
}