//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "OrderSearch",
  data() {
    return {
      formData: this.params,
      evectionStatuList: [
        {
          value: "",
          label: "全部"
        },
        {
          value: 0,
          label: "未开始"
        },
        {
          value: 1,
          label: "待付款"
        },
        {
          value: 2,
          label: "已付款"
        },
        {
          value: 3,
          label: "已完成"
        },
        {
          value: 4,
          label: "已取消"
        }
      ],
      evectionTypeList: [
        {
          value: "",
          label: "全部"
        },
        {
          value: 1,
          label: "个人预定"
        },
        {
          value: 2,
          label: "出差申请"
        }
      ]
    };
  },
  props: {
    search: Function,
    params: Object
  },
  methods: {
    submit() {
      this.$emit("update:params", this.formData);
      this.search();
    }
  },
  created() {},
  activated() {},
  watch: {},
  computed: {}
};
